import Axios from 'axios'
import server from 'util/Server'
import Storage from 'util/store/Storage'
import moment from 'moment'
import { sessionCaducada } from 'util/funciones'
const headers = {
    Authorization: server.obtenerToken(),
    versionapp: server.versionApp,
    versioncode: server.versionCode,
    fecha: moment().format(),
    aplicacion: server.aplicacion,
    identificadordispositivo: server.aplicacion,
    'Content-Type': "application/json"
}
export const getEmpresasHijos = async() => {
    const { empresasHijos } = Storage.getState()
    return new Promise(function(resolve, reject) {
        Axios.get(server.host + server.puertoCentury + '/api/empresa/select/empresa/5/' + empresasHijos, {
                headers: { Authorization: 'Bearer '.concat(sessionStorage.getItem('token')) }
            })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data
                    let estaciones = []
                    if (datos != null) {
                        datos.forEach(empresa => {
                            estaciones.push(empresa)
                        })
                    }
                    resolve(estaciones)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch(err => {
                reject(err, undefined)
            })
    })
}

export const getPosContingencia = async(body) => {
    return Axios.post(server.host + server.puertoCentury + '/api/equipo/select/equipos-desconectados', body, {
        headers: {
            Authorization: server.obtenerToken(),
            ersionapp: server.versionApp,
            versioncode: server.versionCode,
            fecha: moment().format(),
            aplicacion: server.aplicacion,
            identificadordispositivo: server.aplicacion,
            'Content-Type': "application/json",
            'User-Agent': 'Java'
        }
    }, )
}

export const getFacturasContigencia = async(empresas) => {
    return Axios.get(server.host + server.puertoLazoLite + '/guru.facturacion/facturasContingencia/' + empresas, {
        headers: headers
    })
}
export const getIndicadoresFuelly = async sendFetch => {
    return new Promise(function(resolve, reject) {
        Axios.post(server.host + server.puertoMovimiento + '/api/reportes/dashboard/FUELLYMX', sendFetch, {
                headers: headers
            })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data
                    let indicadores = {}
                    if (datos && datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch(err => {
                reject(err, undefined)
            })
    })
}

export const getIndicadoresDatagas = async sendFetch => {
    return new Promise(function(resolve, reject) {
        Axios.post(server.host + server.puertoMovimiento + '/api/reportes/dashboard/DATAGAS', sendFetch, {
                headers: headers
            })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data
                    let indicadores = {}
                    if (datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch(err => {
                reject(err, undefined)
            })
    })
}

export const getIndicadoresLazoLite = async sendFetch => {
    return new Promise(function(resolve, reject) {
        Axios.post(server.host + server.puertoMovimiento + '/api/reportes/dashboard-lite/lazo', sendFetch, {
                headers: headers
            })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data
                    let indicadores = {}
                    if (datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch(err => {
                reject(err, undefined)
            })
    })
}

export const alertaSecadoVirtual = async() => {
    const { empresasHijos } = Storage.getState()
    const headers = {
        Authorization: server.obtenerToken(),
        versionapp: server.versionApp,
        versioncode: server.versionCode,
        fecha: moment().format(),
        aplicacion: server.aplicacion,
        identificadordispositivo: server.aplicacion
    }
    const data = {
        fecha_inicial: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
        fecha_final: moment(new Date()).format('YYYY-MM-DD 24:00:00'),
        identificadorEstacion: empresasHijos
    }
    return new Promise(async function(resolve, reject) {
        Axios.post(server.host + server.puertoMovimiento + '/api/reportes/dashboard_lite/ALERTA_TANQUES', data, {
                headers: headers
            })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data
                    let tanques = []
                    if (datos) {
                        tanques = datos
                    }
                    resolve(tanques)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch(err => {
                reject(err, undefined)
            })
    })
}

export function getDatosGraficaPDV(identificadorEstacion, negocios) {
    return new Promise((resolve, reject) => {
        Axios.post(
                `${server.host}${server.puertoMovimiento}/api/reportes/dashboard-lite/GRAFICA_PDV`, {
                    identificadorEstacion: +identificadorEstacion,
                    negocios,
                    num_mes: new Date().getMonth() + 1
                }, { headers: { Authorization: server.obtenerToken() } }
            )
            .then(response => {
                if (response.status === server.responseOK) {
                    resolve(response.data.data)
                } else if (response.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch(() => {
                reject('Error:--Internal')
            })
    })
}


/* 
"controller" se utiliza para cancelar la peticion cuando se cambia de tipo de reportes 

Se utiliza para la consulta de las ventas de un año, para cancelar la peticion
si se cambia de tipo de reporte mientras se cargan los reportes del año.
*/
export const ventasCombustibleDiarias = async(estacion, fechaInicial, fechaFinal, controller) => {
    return Axios.post(`${server.host}${server.puertoMovimiento}/api/reportes/dashboard-lite/VENTAS_COMBUSTIBLE_DIARIAS`, {
        identificadorEstacion: estacion,
        fecha_inicial: fechaInicial,
        fecha_final: fechaFinal,
    }, { signal: controller.signal })
}

export const ventasCombustibleSemanales = async(estacion, fechaInicial, fechaFinal, controller) => {
    return Axios.post(`${server.host}${server.puertoMovimiento}/api/reportes/dashboard-lite/VENTAS_COMBUSTIBLE_SEMANALES`, {
        identificadorEstacion: estacion,
        fecha_inicial: fechaInicial,
        fecha_final: fechaFinal,
    }, { signal: controller.signal })
}

export const ventasCombustibleMensuales = async(estacion, año, controller) => {
    return Axios.post(`${server.host}${server.puertoMovimiento}/api/reportes/dashboard-lite/VENTAS_COMBUSTIBLE_MENSUALES`, {
        identificadorEstacion: estacion,
        ano: año,
    }, { signal: controller.signal })
}